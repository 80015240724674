import React from 'react';
import { Helmet } from 'react-helmet';

import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';
import Masonry from '../sections/about/Masonry';

const Job = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | Communications Assistant</title>
          <link rel="canonical" href="https://ustrive.com/comms-assistant" />
          <meta
            name="description"
            content="UStrive was started in 2015 as a way to virtually connect students to qualified mentors in a safe and secure environnment."
          />
        </Helmet>
        <div className="pt-24 pt-md-26 pt-lg-30 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-11">
                <div
                  className="text-center mb-12 mb-lg-12"
                  data-aos="zoom-in"
                  data-aos-duration={500}
                  data-aos-delay={500}
                >
                  <h2 className="font-size-11 mb-5">
                    Communications Assistant
                  </h2>
                  <p className="font-size-7 mb-0">
                    Interested in Communications + Digital Media? Join the
                    UStrive Team!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-12">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-11">
                <p>
                  Would you like to gain communications experience with a
                  mission-driven organization? Strive is hiring for a{' '}
                  <strong>communications assistant</strong>! The comms assistant
                  will be responsible for creating our monthly newsletters and
                  producing written copy that can be used for other
                  communications needs. Bonus if you are also able to do some
                  graphic design. Applicants must be in at least their third
                  year of college or have a college degree.
                </p>
                <p>The position will be:</p>
                <ul>
                  <li>
                    About 5-7 hours per week with compensation of $15 per hour.
                  </li>
                  <li>
                    Virtual: Strive staffers work all over the US and Canada!
                  </li>
                  <li>
                    A month-to-month contract that we expect to last at least
                    four months. There is a possibility that the role will
                    become permanent.
                  </li>
                </ul>
                <p>
                  To apply, please send your name, contact info, and answers to
                  the below questions in an attached Word document to Gwyneth at{' '}
                  <a href="mailto:gwyneth@striveforcollege.org">
                    gwyneth@striveforcollege.org
                  </a>{' '}
                  with the subject line: Communications Assistant Application.
                </p>
                <ul>
                  <li>
                    Why are you interested in working as a communications
                    assistant at Strive?
                  </li>
                  <li>
                    What college courses and internship or job experiences did
                    you participate in that will help you succeed as a
                    communications assistant?
                  </li>
                  <li>
                    In creating a digital newsletter, what metrics would you
                    look at to know whether it is successful in engaging an
                    audience?
                  </li>
                  <li>
                    What would you do to improve engagement with a digital
                    newsletter?
                  </li>
                </ul>
                <p>
                  <strong>
                    Applications will be accepted on a rolling basis through
                    February 25.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Masonry />
      </PageWrapper>
    </>
  );
};
export default Job;
